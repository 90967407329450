<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REQUEST INVENTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout col wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              outlined
              v-if="!is_forbidden && !alert"
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch','Regional','Territory']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="selected_branch"
            ></v-select>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2 mb-9"
              v-model="type_of_request"
              dense
              label="Type of Request"
              :items="type_of_request_items"
              item-value="id"
              item-text="particulars"
              :rules="rules.combobox_rule"
            ></v-select>
            <h3>Transmital #:{{transmital_no}}</h3>


            <equipment
              v-if="type_of_request===1"
              :type_of_request="type_of_request"

              :transmital_no="transmital_no"
              :category_id="category_id"
              :branch_id="branch_id"

              v-on:data="on_save_request_data"
            >
            </equipment>

            <car
              v-if="type_of_request===2"
              :type_of_request="type_of_request"

              :transmital_no="transmital_no"
              :category_id="category_id"
              :branch_id="branch_id"

              v-on:data="on_save_request_data"
            >
            </car>

            <building-and-land
              v-if="type_of_request>2"
              :type_of_request="type_of_request"

              :transmital_no="transmital_no"
              :category_id="category_id"
              :branch_id="branch_id"

              v-on:data="on_save_request_data"
            >
            </building-and-land>

            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12" v-if="data_items.length>0">
              <v-btn
                class="w-full"
                color="success"
                @click="save_request"
                v-if="!saving_data"
              >
                Done Transmittal
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-flex>
          <v-flex xs12 md8>
            <v-data-table dense
                          :headers="headers"
                          :items="data_items"
                          height="750"
                          hide-default-footer
                          disable-pagination
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.inventory_particulars.particulars }}
                  </td>
                  <td>
                    {{ item.inventory_particulars_id===1?item.equipment:
                    (item.inventory_particulars_id===2?item.auto_dealer:
                    (item.inventory_particulars_id>2?item.type_of_property:''))}}
                  </td>
                  <td>
                    {{ item.inventory_particulars_id===1?item.equipment_brand_description:
                    (item.inventory_particulars_id===2?item.auto_unit:
                    (item.inventory_particulars_id>2?item.land_title_no:''))}}
                  </td>
                  <td>
                    {{ item.inventory_particulars_id===1?item.equipment_serial_no:
                    (item.inventory_particulars_id===2?item.auto_mv_file_no:
                    (item.inventory_particulars_id>2?item.location:''))}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-form>

    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import Equipment from './components/Equipment'
  import Car from './components/Car'
  import BuildingAndLand from './components/BuildingAndLand'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: 0,
      is_forbidden: true,
      branch_id: 0,
      selection: '',
      transmital_no: 0,
      type_of_request: 3,

      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Particulars', value: 'inventory_particulars.particulars', sortable: true},
        {text: 'Data', value: 'equipment', sortable: true},
        {text: 'Details', value: 'equipment_brand_description', sortable: true},
        {text: 'No.', value: 'equipment_brand_description', sortable: true},
      ],
      data_items: [],
      branch_items: [],
      category_items: [],
      type_of_request_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      Equipment,
      Car,
      BuildingAndLand,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('inventory', ['initialize_request_inventory', 'list_of_transmital_stocks', 'done_transmital_stocks']),
      initialize_data() {
        this.initialize_request_inventory()
          .then(response => {
            this.category_items = response.data[0].category
            this.type_of_request_items = response.data[0].type_of_request
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {

        this.is_forbidden = true

        // if (this.category_id === 4 || this.category_id === 3) {
        //   this.is_forbidden = false
        // } else {
        //   this.selection = ''
        // }
        this.selection = ''
        this.branch_id = -1
        this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_branch() {
        this.list_of_transmital_stocks({
          category_id: this.category_id,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.transmital_no = response.data[0].transmital_no
            this.data_items = response.data[0].data
          })
          .catch(error => {
            console.log(error)
          })
      },
      on_save_request_data() {
        this.selected_branch()
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.data_items.length > 0) {
          this.done_transmital_stocks({
            category_id: this.category_id,
            branch_id: this.branch_id,
          })
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'No Data'
          this.saving_data = false
        }
      },
    },
  }
</script>
